/* ashtech custom */

/*start left image and right test div*/
.bg {
    background: #f4f5f7;
}
.side_block {
    position: relative;
}

* {
    outline: none;
}
@media (min-width: 1024px){
    .side_block.extream_img img.img-fluid {
        max-width: 470px;
    }
    .hero-right-text{
        display: none;
    }
    .csection_2_img{
        max-width:450px;
    }
}
@media (max-width: 590px){
    .right-text-block .right-text-title {
        display: none;
    }
    .csection_2_img{
        max-width:100%;
    }
}
.hero-right-text{
    background: #0f162fbd;
    padding: 16px 23px;
    border-radius: 20px;
}
.hero-right-text h3 {
    color: #00aef5;
}
.hero-right-text small {
    color: #c9c9c9;
}
.right-text-block .right-text-title {
    position: absolute;
    right: -22%;
    top: 41%;
}
.right-text-block .right-text-title {
    width: 49% !important;
    background: #0f162fbd;
    padding: 16px 23px;
    border-radius: 20px;
    box-shadow: 0px 2px 6px 0px #aaa;
}

.side_block img {
    display: table;
    align-items: center;
    margin: 0 auto;
}
.right-text-title .title{
    color: #00aef5;
}
.right-text-title small{
    color: #c9c9c9;
}
/*end left image and right test div*/

/*myblocks css*/
.block {
    display: flex;
    flex-direction: column;
    transition: all ease 0.4s;
    height: auto;
    border-radius: 1.4rem;
    padding: 37px 34px;
    background: #ffffff;
    margin-bottom: 30px;
    box-shadow: 0 0 12px 0px rgb(2 2 2 / 41%);
    -webkit-box-shadow: 0 0 12px 0px rgb(2 2 2 / 41%);
}
.block p{
    /* color: #9b9b9b; */
    font-size: 14px;
}

.block h4{
    color: #3f3850;
    font-weight: 700;
}

.block-image {
    display: flex;
    margin-bottom: 1rem;
    align-items: center;
    justify-items: flex-start;
}
.block-image .block-image-icon img {
    color: #03b97c;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 30px;
    background: transparent;
    flex: 1;
    width: 80px;
}
/*end myblock css*/