.product .content .title a{
    white-space: pre-wrap;
    overflow: visible;
}

/* Arrow Shaps */
.bg-blue_arrow1{
  background:#1893bc ;
}
.bg-blue_arrow1:after{
  border-left: 25px solid #1893bc;
}
.bg-blue_arrow2{
  background:#33beee ;
}
.bg-blue_arrow2:after{
  border-left: 25px solid #33beee;
}
.bg-blue_arrow3{
  background:#15576e ;
}
.bg-blue_arrow3:after{
  border-left: 25px solid #15576e;
}

.box_arrow {
    width: 90%;
    height: 35px;
    /* background: tomato; */
    text-align: center;
    color: #fff;
    font-size: 13px;
    position: relative;
    display: table;
  }
  
  .box_arrow span {
    display: table-cell;
    vertical-align: middle;
  }
  
  .box_arrow:before,
  .box_arrow:after {
    content: '';
    position: absolute;
  }
  
  .box_arrow:after {
    
    border-right: 25px solid transparent;
    border-top: 18px solid transparent;
    border-bottom: 17px solid transparent;
    right: -50px;
    top: 0px;
    width: 0;
    height: 0;
    display: block;
  }
  
  .box_arrow:before {
    border-left: 15px solid white;
    border-right: 15px solid transparent;
    border-top: 19px solid transparent;
    border-bottom: 20px solid transparent;
    left: 0px;
    top: -1px;
    width: 0;
    height: 0;
    display: block;
  }