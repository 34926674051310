.about-area .about-left-image img{
    /* max-height: 50vh;
    max-width: 50%; */
    border-radius: 10px;
    text-align: center;
}
.about-left-image{
    margin-left: auto;
  margin-right: auto;
  /* width: 50%; */
}
.border{
    text-align: center;
    border: 1px solid #dee2e6!important;
}
.about-left-image img{
    border-radius: 4px;
    padding: 10px 10px;
}
.left_img_txt_desc{
    /* background-color: #0c694233; */
}
.left_img_txt_desc p {
    display: flex;
    /* justify-content: center; */
}
.card-img-left{
    max-width: 100%;
}
@media only screen and (min-width: 700px){
.left_overlay{
    right: 10px;
    position: relative;
    padding-right: 35px;
    padding-left: 10px;
    padding-top:20px;
    padding-bottom:20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow:5px 3px 9px 1px #6c757d6c;
    width:120%;
}
.right_overlay{
    right: 100px;
    position: relative;
    padding-right: 35px;
    padding-left: 10px;
    padding-top:20px;
    padding-bottom:20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow:5px 3px 9px 1px #6c757d6c;
    width:120%;
}
}
@media only screen and (max-width: 650px){
    .left_overlay{
        /* right: 10px; */
        position: relative;
        padding-right: 35px;
        padding-left: 10px;
        background-color: #fff;
        border-radius: 5px;
        box-shadow:5px 3px 9px 1px #6c757d6c;
        width:100%;
    }
    .right_overlay{
        /* right: 100px; */
        position: relative;
        padding-right: 35px;
        padding-left: 10px;
        background-color: #fff;
        border-radius: 5px;
        box-shadow:5px 3px 9px 1px #6c757d6c;
        width:100%;
    }
    }
