/* section wise */
@media (min-width: 700px){
.overlap-1{
margin: -14px auto 66px calc(((100% - 1140px) / 2)) !important;
}
}
@media (max-width: 605px){
   .overlap-1 {
    width: 340px !important;
    margin-top: 85px !important;
    margin-left: calc(((100% - 340px) / 2)) !important;
} 
}

.u-section-2 {
    background-image: none;
    min-height: 1060px;
  }
  .u-section-2 .u-shape-1 {
    height: 943px;
    width: calc(((100% - 1140px) / 2) + 702px);
    margin: 0 auto 0 0;
  }
  .u-section-2 .u-image-1 {
    width: auto;
    height: 500px;
    margin: -920px calc(((100% - 1140px) / 2)) 0 auto;
  }
  .u-section-2 .u-group-1 {
    min-height: 232px;
    width: 570px;
    margin: -469px auto 0 calc(((100% - 1140px) / 2));
  }
  .u-section-2 .u-container-layout-1 {
    padding: 30px;
  }
  .u-section-2 .u-text-1 {
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-family: Roboto, sans-serif;
    margin: 0 auto 0 0;
  }
  .u-section-2 .u-text-2 {
    font-family: Oswald, sans-serif;
    font-size: 2rem;
    margin: 20px 0 0;
  }
  .u-section-2 .u-text-3 {
    margin: 20px 1px 0 0;
  }
  .u-section-2 .u-btn-1 {
    border-style: none none solid;
    padding: 0;
  }
  .u-section-2 .u-list-1 {
    width: 1089px;
    margin: 100px auto 66px calc(((100% - 1140px) / 2));
  }
  .u-section-2 .u-repeater-1 {
    grid-template-columns: repeat(3, calc(33.33% - 13.5px));
    min-height: 293px;
    grid-gap: 18px;
  }
  .u-section-2 .u-list-item-1 {
    background-image: none;
    box-shadow: 5px 5px 20px 0 rgba(0,0,0,0.4);
  }
  .u-section-2 .u-container-layout-2 {
    padding: 30px;
  }
  .u-section-2 .u-icon-1 {
    height: 62px;
    width: 62px;
    margin: 0 auto 0 0;
  }
  .u-section-2 .u-text-4 {
    font-size: 1.25rem;
    letter-spacing: 1px;
    background-image: none;
    font-weight: 700;
    margin: 30px 2px 0 0;
  }
  .u-section-2 .u-text-5 {
    font-style: italic;
    margin: 30px 0 0;
  }
  .u-section-2 .u-list-item-2 {
    box-shadow: 5px 5px 20px 0 rgba(0,0,0,0.4);
  }
  .u-section-2 .u-container-layout-3 {
    padding: 30px;
  }
  .u-section-2 .u-icon-2 {
    height: 62px;
    width: 62px;
    margin: 0 auto 0 0;
  }
  .u-section-2 .u-text-6 {
    font-size: 1.25rem;
    letter-spacing: 1px;
    background-image: none;
    font-weight: 700;
    margin: 30px 2px 0 0;
  }
  .u-section-2 .u-text-7 {
    font-style: italic;
    margin: 30px 0 0;
  }
  .u-section-2 .u-list-item-3 {
    box-shadow: 5px 5px 20px 0 rgba(0,0,0,0.4);
  }
  .u-section-2 .u-container-layout-4 {
    padding: 30px;
  }
  .u-section-2 .u-icon-3 {
    height: 62px;
    width: 62px;
    margin: 0 auto 0 0;
  }
  .u-section-2 .u-text-8 {
    font-size: 1.25rem;
    letter-spacing: 1px;
    background-image: none;
    font-weight: 700;
    margin: 30px 2px 0 0;
  }
  .u-section-2 .u-text-9 {
    font-style: italic;
    margin: 30px 0 0;
  }
  .u-section-2 .u-list-item-4 {
    box-shadow: 5px 5px 20px 0 rgba(0,0,0,0.4);
  }
  .u-section-2 .u-container-layout-5 {
    padding: 30px;
  }
  .u-section-2 .u-icon-4 {
    height: 62px;
    width: 62px;
    margin: 0 auto 0 0;
  }
  .u-section-2 .u-text-10 {
    font-size: 1.25rem;
    letter-spacing: 1px;
    background-image: none;
    font-weight: 700;
    margin: 30px 2px 0 0;
  }
  .u-section-2 .u-text-11 {
    font-style: italic;
    margin: 30px 0 0;
  }
  @media (max-width: 1199px) {
     .u-section-2 {
      min-height: 1244px;
    }
    .u-section-2 .u-shape-1 {
      height: 1179px;
      width: calc(((100% - 940px) / 2) + 735px);
    }
    .u-section-2 .u-image-1 {
      width: 771px;
      height: 587px;
      margin-top: -928px;
      margin-right: calc(((100% - 940px) / 2));
    }
    .u-section-2 .u-group-1 {
      min-height: 381px;
      width: 526px;
      margin-top: -767px;
      margin-left: calc(((100% - 940px) / 2));
    }
    .u-section-2 .u-list-1 {
      width: 909px;
      margin-top: 337px;
      margin-bottom: 60px;
      margin-left: calc(((100% - 940px) / 2));
    }
    .u-section-2 .u-repeater-1 {
      min-height: 321px;
    }
    .u-section-2 .u-container-layout-2 {
      padding-left: 20px;
      padding-right: 20px;
    }
    .u-section-2 .u-text-4 {
      margin-right: 0;
    }
    .u-section-2 .u-container-layout-3 {
      padding-left: 20px;
      padding-right: 20px;
    }
    .u-section-2 .u-text-6 {
      margin-right: 0;
    }
    .u-section-2 .u-container-layout-4 {
      padding-left: 20px;
      padding-right: 20px;
    }
    .u-section-2 .u-text-8 {
      margin-right: 0;
    }
    .u-section-2 .u-container-layout-5 {
      padding-left: 20px;
      padding-right: 20px;
    }
    .u-section-2 .u-text-10 {
      margin-right: 0;
    }
  }
  @media (max-width: 991px) {
     .u-section-2 {
      min-height: 1648px;
    }
    .u-section-2 .u-shape-1 {
      height: 1588px;
      width: calc(((100% - 720px) / 2) + 605px);
    }
    .u-section-2 .u-image-1 {
      width: 622px;
      height: 532px;
      /* margin-top: -1186px; */
      margin-right: calc(((100% - 720px) / 2));
    }

    .u-section-2 .u-group-1 {
      margin-top: -863px;
      margin-left: calc(((100% - 720px) / 2));
    }
    .u-section-2 .u-text-2 {
      font-size: 4.375rem;
    }
    .u-section-2 .u-list-1 {
      width: 655px;
      margin-top: 406px;
      margin-left: calc(((100% - 720px) / 2));
    }
    .u-section-2 .u-repeater-1 {
      grid-template-columns: repeat(2, calc(50% - 9px));
      min-height: 655px;
    }
    .u-section-2 .u-text-4 {
      font-size: 1.875rem;
    }
    .u-section-2 .u-text-6 {
      font-size: 1.875rem;
    }
    .u-section-2 .u-text-8 {
      font-size: 1.875rem;
    }
    .u-section-2 .u-text-10 {
      font-size: 1.875rem;
    }
  }
  @media (max-width: 767px) {
     .u-section-2 {
      min-height: 2052px;
    }
    .u-section-2 .u-shape-1 {
      height: 1497px;
      width: calc(((100% - 540px) / 2) + 484px);
    }
    .u-section-2 .u-image-1 {
      width: 476px;
      height: 482px;
      /* margin-top: -1631px; */
      margin-right: calc(((100% - 540px) / 2));
    }
    .u-section-2 .u-group-1 {
      min-height: 339px;
      width: 453px;
      margin-top: -760px;
      margin-left: calc(((100% - 540px) / 2));
    }
    .u-section-2 .u-container-layout-1 {
      padding-left: 20px;
      padding-right: 20px;
    }
    .u-section-2 .u-text-2 {
      font-size: 3.75rem;
    }
    .u-section-2 .u-list-1 {
      width: 380px;
      margin-top: 341px;
      margin-left: calc(((100% - 540px) / 2));
    }
    .u-section-2 .u-repeater-1 {
      grid-template-columns: 100%;
      min-height: 1156px;
    }
  }
  @media (max-width: 575px) {
     .u-section-2 {
      min-height: 2029px;
    }
    .u-section-2 .u-shape-1 {
      width: calc(((100% - 340px) / 2) + 249px);
    }
    .u-section-2 .u-image-1 {
      width: 80%;
      height: auto;
      object-position: 73.14% 50%;
      /* margin-top: -1686px; */
      margin-right: auto;
    }
    .u-section-2 .u-group-1 {
      min-height: 307px;
      width: 307px;
      margin-top: -670px;
      margin-left: auto;
    }
    .u-section-2 .u-text-2 {
      font-size: 2.5rem;
    }
    .u-section-2 .u-text-3 {
      margin-right: 0;
    }
    .u-section-2 .u-list-1 {
      width: 340px;
      margin-top: 396px;
      margin-left: calc(((100% - 340px) / 2));
    }
  }