.grid_image_cs {
    margin-bottom: 10px;
    color:#fff;
    cursor: pointer;
}
.pre_txt-hover{

    position: absolute;
    opacity: 1;
    transition: .5s ease;
    display: table-cell;
  vertical-align: middle ;
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    /* -ms-transform: translate(-50%, -50%); */
    text-align: center;
    /* width: 100%; */
    top:32%;
    left:40%;
    /* height: 100vh; */
    align-items: center !important;
   
}
.txt-hover{
    position: absolute;
    opacity: 0;
    transition: .5s ease;
    top: 0;
    left:0;
    display: table-cell;
  vertical-align: middle ;
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    /* -ms-transform: translate(-50%, -50%); */
    text-align: center;
    width: 100%;
    margin:auto;
    /* height: 100vh; */
}
.grid_image_cs:hover .txt-hover {
    top:50%;
    opacity: 1;
    background-color: #e8eceb27;
    padding:10px 10px;
    position: absolute;
    width: 100%;
    /* height: 100vh; */
    text-align: center;
    margin:auto;
  }
  .grid_image_cs:hover .pre_txt-hover {
    opacity: 0 !important;
  }
