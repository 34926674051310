* {
    margin: 0;
    padding: 0
}

html {
    height: 100%
}

p {
    /* color: grey */
}
.curret_fsFsFix{
    cursor: pointer;
}

#heading {
    text-transform: uppercase;
    color: #209F5A;
    font-weight: normal
}

.msform {
    text-align: center;
    position: relative;
    margin-top: 20px
}

.msform fieldset {
    background: white;
    border: 0 none;
    border-radius: 0.5rem;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding-bottom: 20px;
    position: relative;
    margin: auto;
}

.form-card {
    text-align: left
}

.msform fieldset:not(:first-of-type) {
    display: none
}

/* .msform input,
.msform textarea {
    padding: 8px 15px 8px 15px;
    border: 1px solid #ccc;
    border-radius: 0px;
    margin-bottom: 25px;
    margin-top: 2px;
    width: 100%;
    box-sizing: border-box;
    color: #2C3E50;
    background-color: #ECEFF1;
    font-size: 16px;
    letter-spacing: 1px
} */
/* 
.msform input:focus,
.msform textarea:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid #209F5A;
    outline-width: 0
} */

.msform .action-button {
    width: 100px;
    background: #209F5A;
    font-weight: bold;
    color: white;
    border: 0 none;
    border-radius: 0px;
    cursor: pointer;
    padding: 10px 5px;
    margin: 10px 0px 10px 5px;
    float: right
}

.msform .action-button:hover,
.msform .action-button:focus {
    background-color: #054e27
}

.msform .action-button-previous {
    width: 100px;
    background: #616161;
    font-weight: bold;
    color: white;
    border: 0 none;
    border-radius: 0px;
    cursor: pointer;
    padding: 10px 5px;
    margin: 10px 5px 10px 0px;
    float: right
}

.msform .action-button-previous:hover,
.msform .action-button-previous:focus {
    background-color: #000000
}

.card {
    z-index: 0;
    border: none;
    position: relative
}

.fs-title {
    font-size: 25px;
    color: #209F5A;
    margin-bottom: 15px;
    font-weight: normal;
    text-align: left
}

.purple-text {
    color: #209F5A;
    font-weight: normal
}

.steps {
    font-size: 25px;
    color: gray;
    margin-bottom: 10px;
    font-weight: normal;
    text-align: right
}

.fieldlabels {
    color: gray;
    text-align: left
}

#progressbar {
    margin-bottom: 30px;
    overflow: hidden;
    color: lightgrey;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#progressbar .active {
    color: #209F5A
}

#progressbar li {
    list-style-type: none;
    font-size: 15px;
    width: 25%;
    float: left;
    position: relative;
    font-weight: 400;
}
#progressbar #step0:before{
    font-family: FontAwesome;
    /* content: "\f007"; */
}
#progressbar #step1:before{
    font-family: FontAwesome;
    /* content: "\f13e"; */
}
#progressbar #step2:before{
    font-family: FontAwesome;
    /* content: "\f13e"; */
}
#progressbar #step3:before{
    font-family: FontAwesome;
    /* content: "\f13e"; */
}
#progressbar #step4:before{
    font-family: FontAwesome;
    /* content: "\f13e"; */
}

#progressbar #account:before {
    font-family: FontAwesome;
    /* content: "\f13e"; */
}

#progressbar #personal:before {
    font-family: FontAwesome;
    content: "\f007"
}

#progressbar #payment:before {
    font-family: FontAwesome;
    content: "\f030"
}

#progressbar #confirm:before {
    font-family: FontAwesome;
    content: "\f00c"
}

#progressbar li:before {
    width: 50px;
    height: 50px;
    line-height: 45px;
    display: block;
    font-size: 20px;
    color: #ffffff;
    background: lightgray;
    border-radius: 50%;
    margin: 0 auto 10px auto;
    padding: 2px
}

#progressbar li:after {
    content: '';
    width: 100%;
    height: 2px;
    background: lightgray;
    position: absolute;
    left: 0;
    top: 25px;
    z-index: -1
}

#progressbar li.active:before,
#progressbar li.active:after {
    background: #209F5A
}

.progress {
    height: 20px
}

.progress-bar {
    background-color: #209F5A
}

.fit-image {
    width: 100%;
    object-fit: cover
}
.modal-body{
    background: #fff;
}
label {
    /* Other styling... */
    /* text-align: right; */
    clear: both;
    float:left;
    /* margin-right:15px; */
}
fieldset input{
    line-height: 0px;
    /* text-align: center; */
}