
.main_title{
    color:white !important;
    font-size: 1.5rem;
    font-family: 'Source Sans Pro', sans-serif !important;
}
.nav-sidebar .nav-item .nav-link .fa{
    margin-right: 1rem;
    
}
.nav-sidebar .nav-item  a{
    border-bottom: rgb(29 29 29 / 22%);
    border-bottom-style: groove;
}
.menu-center{
    justify-content: center !important;
}
.header-bottom {
    padding: 3px 0;
}
.sticky-nav.menu_fixed{
    background-color: #ffffffc7 !important;
}

  @media only screen and (min-width: 600px){
    /* For portrait layouts only */
    .header-logo img{
        max-width: 90px !important;
        height: 55px;
    }
  }
@media (max-width: 600px ) {
   
}

.logo_desk{
    max-width: 187px;
    height: auto;
    margin: auto;
  }
.bg-black{
    /* background-color:#277373; */
    background:#0c6942;
    /* background-color: ; */
}
.main-menu{
    justify-content: right;
}
.main-menu ul li.dropdown ul li a{
    height: auto;
    
}
.main-menu ul li a{
    font-size: 18px;
    text-transform: capitalize !important;
     /* font-weight: 600; */
}
.main-menu ul li.dropdown ul li a{
    font-weight: bold;
}
.header-top-massege p{
    color:#fff;
}
.header-top-massege1 p{
    color:#fff;
}
.flex-grow-2{
    flex-grow:2 !important;;
}
.header-top{
    padding:1px 1px;
}
.header-action-btn a i::before{
   /* margin-top: 5px; */
}
.btn-warning {
    color: #000;
    background-color: #ff7004;
    border-color: #fff;
}
@media only screen and (max-width: 600px){
    /* For portrait layouts only */
    .header-logo img{
        width: auto;
        height: 37px;
    }
    .custom-search{
        display: none;
    }
    
  }
  @media only screen and (min-width: 600px){
    /* For portrait layouts only */
    .header-logo img{
        max-width: 60px !important;
        height: 40px;
    }
    .custom-search{
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 100px;
        padding-right: 100px;

    }
    .custom-search select{
        font-size: medium;
        background-color: #e9ecef;
    }
    .header_account_list{
        display: none;
    }
  }

.sticky-header{
    /* position: fixed; */
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    animation: sticky 1s;
    box-shadow: 2px 4px 8px rgb(51 51 51 / 25%);
}
.main-menu ul li.dropdown ul.sub-menu{
    position:absolute;
    z-index:9;
    text-align:left;
    opacity:0;
    visibility:hidden;
    -o-transform-origin:0 0;
    -ms-transform-origin:0 0;
    -moz-transform-origin:0 0;
    -webkit-transform-origin:0 0;
    transform-style:preserve-3d;
    -o-transform-style:preserve-3d;
    -moz-transform-style:preserve-3d;
    -webkit-transform-style:preserve-3d;
    transform:rotateX(-75deg);
    -o-transform:rotateX(-75deg);
    -moz-transform:rotateX(-75deg);
    -webkit-transform:rotateX(-75deg);
    /* min-width:205px; */
    left:auto!important;
    background:#fff;
    box-shadow:0 0 3.76px .24px rgba(0,0,0,.15);
    display:block;
    -o-transition:-o-transform .3s,opacity .3s;
    -ms-transition:-ms-transform .3s,opacity .3s;
    -moz-transition:-moz-transform .3s,opacity .3s;
    -webkit-transition:-webkit-transform .3s,opacity .3s;
}
.main-menu ul li.dropdown ul.sub-menu{
    /* min-width: 150px; */
    white-space: nowrap;
    width: auto;
    max-width: auto;
    padding:0px 2px;
    word-wrap: normal;
    /* position: relative; */
    /* overflow-wrap: break-word; */
    right:0px;
}
.main-menu ul li.dropdown ul li a{
    padding:10px 20px 10px 20px;
}
.main-menu ul li a i{
    width: 20px;
    color: #ff5757;
    margin-top: auto;
    font-size: 18px;
}
/* Verticle Tabs */
@media (max-width: 600px ) {
    .vertical_tab{
        display: none;
    }
}
.vertical_tab{
    position: fixed;
    margin-left: 2%;
    top:35%;
    margin-bottom: 5%;
    color: #fff;
}
.virtical {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: auto;
    /* background-color: #1816162e; */
   
  }
  
  .virtical li a {
    display: block;
    color: #fff !important;
    padding: 3px 4px;
    text-decoration: none;
    /* border-right:5px solid red; */
    border-left:1px solid rgba(0, 0, 0, 0.186)  ;
  }
  
  /* Change the link color on hover */
  .virtical  li a:hover {
    background-color: rgba(85, 85, 85, 0.163);
    color: white;
    border-left:1px solid #fff  ;
  }
  html{
    scroll-behavior: smooth;
    }

    .card-primary.card-outline {
        border-top: 3px solid #007bff;
    }
    .cover-height    {
        min-height: 80vh;
        /* overflow-y: scroll; */
    }
    .card-header {
        background-color: transparent;
        border-bottom: 1px solid rgba(0,0,0,.125);
        padding: 0.75rem 1.25rem;
        position: relative;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
    }

