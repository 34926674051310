@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@100&display=swap');

/* @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&amp;display=swap"); */
@import url(//netdna.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.css);
@import url(./assets/css/vendor/vendor.min.css);

@import url(./assets/css/plugins/plugins.min.css);
@import url(./assets/css/style.min.css);
@import url(./assets/css/aos.css);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/css/select2.min.css);
/* @import url("https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap.min.css"); */
/* @import url('https://fonts.googleapis.com/css2?family=STIX+Two+Text&display=swap'); */
/* @import url(./assets/NeueMontreal-Regular.otf); */
.dot-carousel {
    position: relative;
    left: -9999px;
    width: 10px !important;
    height: 10px !important;
    border-radius: 5px !important;
    background-color: #9880ff;
    color: #9880ff;
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff;
    animation: dotCarousel 1.5s infinite linear;
}

@keyframes dotCarousel {
  0% {
    box-shadow: 9984px 0 0 -1px #9880ff, 9999px 0 0 1px #9880ff, 10014px 0 0 -1px #9880ff;
  }
  50% {
    box-shadow: 10014px 0 0 -1px #9880ff, 9984px 0 0 -1px #9880ff, 9999px 0 0 1px #9880ff;
  }
  100% {
    box-shadow: 9999px 0 0 1px #9880ff, 10014px 0 0 -1px #9880ff, 9984px 0 0 -1px #9880ff;
  }
}


.mainLoginInput{
  /* height: 40px; */
  padding: 0px;
  /* font-size: 30px; */
  margin: 5px 0;
}

.mainLoginInput::-webkit-input-placeholder { 
font-family: FontAwesome;
font-weight: normal;
overflow: visible;
vertical-align: top;
display: inline-block !important;
padding-left: 5px;
padding-top: 2px;
/* color: hsl(9, 40%, 60%); */
}

.mainLoginInput::-moz-placeholder  { 
font-family: FontAwesome;
font-weight: normal;
overflow: visible;
vertical-align: top;
display: inline-block !important;
padding-left: 5px;
padding-top: 2px;
/* color: hsl(9, 40%, 60%); */
}

.mainLoginInput:-ms-input-placeholder  { 
font-family: FontAwesome;
font-weight: normal;
overflow: visible;
vertical-align: top;
display: inline-block !important;
padding-left: 5px;
padding-top: 2px;
/* color: hsl(9, 40%, 60%); */
}
.updthumb {
  height: 50px;
  width: 50px;
}
.padding-thumg-job{
  padding: 30px 1px;
  border: 2px dashed #ddd;
  border-radius: 10px;
  align-items: center;
  

}
.padding-thumg-job .updthumb{
cursor: pointer;
}
.comment-span{
  font-size: 13px;
  padding-top: 5px;
}
.updImgThumb {
  display: none;
}
.pointer{
  cursor:pointer;
}
.bg-primary{
  background-color:#1c4ea6!important;
}
.border-style1{
  border: 1px dashed #60417c;
}
.imgLibThumb img {
  float: left;
  object-fit: cover;
  max-width: 100%;
  padding: 5px 5px;
}
.dtgrid>thead{
  background-color: #a92e23;;
  color:#fff;
}
.image_thumb_parent{
  position: relative;
}
.image_thumb_parent .icon-close{
  cursor: pointer;
  position: absolute;
    color: #000;
    top: 0;
    right: 0;
    z-index: 9;
    background: #fff;
    font-size: 16px;
 margin-right: 4px;   
}


/* scroll bar webkit */
::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  /* border-radius: 10px; */
  /* background: #f1f1f1; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1c4ea4;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000;
}
table{
  max-width: 100% !important;
  position: relative;
  /* word-break: break-all; */
}
.parent_tb {
  height: 10%;
  width: 100%;
  /* background-color: grey; */
  /* font-size: 1.6em; */
  /* border-bottom: 1px solid #e1e1e1; */
  display: table;
}

.line_tb {
  display: table-cell;
  vertical-align: middle;
}

.left_tb {
  margin-left: 2%;
  float: left;
  /* background-color: yellow; */
}

.right_tb {
  margin-right: 2%;
  float: right;
  /* background-color: red; */
}
.sub-quest{
  padding: 10px 10px
}
.row{
  margin-left:0px;
  margin-right:0px;
}
/* end tablecell */
/* end of webkit */
@import url(./assets/css/paggination.css);

