.bg-gogreen{
    /* background-image: url('../assets/images/BG-Doodle-tp.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover; */
    /* -webkit-filter: grayscale(100%);  */
    /* filter: grayscale(100%); */
    /*
background-image: url('');
background: no-repeat;
background-size: cover;
position: relative;
background-position: center;
*/
}
.bg-gogreen .border-box {

    /* background: #fff; */
}
.border-box .tab-content{
    background-color: #fff;
}
.footer-area{
    background:#c3d1e8;
    color:#000;
  }
  .footer-bottom {
    /* background: #277373 none repeat scroll 0 0; */
    /* background: #0c6942 none repeat scroll 0 0; */
    background-color: #c3d1e8;
    padding: 25px 0 10px 0;
}
.footer-area .footer-top .single-wedge .footer-links .single-link{
    color:#000;
    font-weight: 600;
}
.footer-area .footer-top .single-wedge .footer-herading{
    color:#000;
}
.footer-bottom .copy-text{
    color:#000;
}
.link-follow a{
    color:#006565;
}
.border-top{
    border-top:1px solid #8e5a81!important;
}
@media only screen and (max-width: 600px){
    .loginform{
        margin:5% 5% 5% 5%;
    }
    .loginform .formname{
        padding: 30px 20px 0 20px;
        font-size: 18px;
        font-family: "Montserrat",sans-serif;
    }
    .loginform .formtxt{
        padding-left: 20px;
        font-size: 15px;
        padding-bottom: 20px;
    }
    .loginform #logo{
        top:20px;
        margin-right: 10px;
        float: right;
        width: 40px;
        height: auto;
    }
}
@media only screen and (min-width: 600px){
    .loginform{
        margin:5% 25% 5% 25%;
    }
    .loginform .formname{
        padding: 30px 20px 0 20px;
        font-size: 30px;
        font-family: "Montserrat",sans-serif;
    }
    .loginform .formtxt{
        padding-left: 20px;
        font-size: 18px;
        padding-bottom: 20px;
    }
    .loginform #logo{
        top:20px;
        margin-right: 10px;
        float: right;
        width: 130px;
        height: auto;
    }
}

.loginform .header{
    background-color:#0c6942;
    color:#fff;
    margin-bottom: 0;
    }
.loginform .fdiv{
    margin: 0;
    padding: 0;
    background-color: #fff;
}
.loginform .formarea{
    margin-top:0;
    padding: 40px 40px 40px 40px;    
    background-color: #fff;
    align-items: center;

}

.loginform input[type=text]{
    width: 100%;
    padding: 12px;
    outline: 0;
    border-width: 0 0 2px;
     border-color: blue;
     /* font-size: 18px; */
     font-family: "Poppins",sans-serif;
     font-weight: 500;
  /*  border-radius: 4px;
    box-sizing: border-box; */
    resize: vertical;
  }
  .loginform input[type=password]{
    width: 100%;
    padding: 12px;
    outline: 0;
    border-width: 0 0 2px;
     border-color: blue;
     /* font-size: 18px; */
     font-family: "Poppins",sans-serif;
     font-weight: 500;
  /*  border-radius: 4px;
    box-sizing: border-box; */
    resize: vertical;
  }
 .loginform .btn-primary{
     margin-top: 60px;
     width: 40%;
     background-color: orange;
     padding: 10px;
     border-radius: 4px;
     cursor: pointer;
     border-radius: 23px;
 } 
.loginform input[type=submit] {
      margin-top: 60px;
    padding: 15px;
      width: 100%;
    background-color: orange;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    /* font-size: 25px; */
  }
.loginform .bottomtxt{
      text-align: center;
      padding-top: 20px;
      /* font-size: 18px; */
  }
  .login-register-tab-list a.active{
     text-decoration: underline;
    font-size: 32px;
    /* text-shadow: 2px 2px #d65f25;  */

  }
  
