.billing-info{
    display: flex;
    flex-direction: row;
    align-items: center;
    /* width: 70%;
    margin-left: 5px;
    float: right; */
}

.billing-info input{
    margin-left: 5px;
}

.billing-select {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* width: 70%;
    margin-left: 5px;
    float: right; */
}
.billing-select select{
    margin-left: 5px;
}
input[type="radio"] {
    height: 20px;
    width: 20px;
    margin-right: 5px;
}