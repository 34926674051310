.bg_blog_category{
background-color: #00aaef45;
padding: 2px 2px;

border-radius: 7px 7px 7px 0px;
font-size:13px;
font-weight: bold;
color: #1ca8d8;
margin: 7px 2px 20px;
}
.left-sidebar .category-post{
    padding: 5px 5px;
}
 .sidebar-widget-tag li a {
    display: block;
    float: left;
    padding: 10px 25px;
    font-size: 14px;
    line-height: 20px;
    color: #474747;
    font-weight: 600;
    border: 1px solid #ebebeb;
    border-radius: 5px;
    /* background: #fff; */
    /* margin: 4px; */
    text-transform: capitalize;
}
.sidebar-widget-tag li {
    line-height: 1;
    float: left;
    list-style: none;
}
.sidebar-widget-tag li a:hover{background-color:#00aaef;border-color:#00aaef;color:#fff}