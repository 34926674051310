.bgCard{
    background-color: #ffffffbf !important;
}


body{
    /* background-image: url(../assets/images/bg-3.jpg); */
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;

      /* background-image: url('../assets/images/bg-2.jpg'); */
         /* background: #fff; */
    /* color:#7b0000; */
    /* background-position: center; */
    /* background-repeat: no-repeat;  */
    /* background-size: cover; */
    

   
    
}
p{
    margin-bottom: 0px;
}
@media only screen and (max-width: 600px){
    .container, .container-lg, .container-md, .container-xl {
        max-width: 100%!important; 
        /* max-width:90%!important */
   }
   .container-sm
{
    max-width: 80% !important;
}
.mob_lenght{
    max-height: 116px;
    max-width: 60%;
    overflow: hidden;
    display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color:#fff;
  /* background-color: rgba(128, 128, 128, 0.119); */
}
}
@media only screen and (min-width: 650px){
    .container, .container-lg, .container-xl {
       
         max-width:100% !important; 
   }
   .container-md{
    max-width: 75%;
   }
.container-sm
{
    max-width: 50% !important;
}
.mob_lenght{
    max-width: 80%;
    /* max-height: 200px;
    overflow: hidden; */
}
}
@media only screen and (max-width: 600px){
.container-middle{
    padding-left: 5%;
}
}
@media only screen and (min-width: 700px){
    .container-middle{
        padding-left: 17%;
    }
}

.container_top{
    max-width: 95% !important;
    --bs-gutter-x: 30px;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x)/ 2);
    padding-left: calc(var(--bs-gutter-x)/ 2);
    margin-right: auto;
    margin-left: auto;
}
.wrapper{
    /* opacity: 999; */
    /* background: #dcd7d747; */
}
section{
     /* background: #ffffffb0;  */
}
.green{
    /* background:#0c6942; */
    /* background: #2b2f33; */
    background:#fff !important;
    /* box-shadow:5px 0px 10px rgb(0 0 0 / 50%); */
}
.main-menu.manu-color-white ul li a{
    /* color:#594747; */
    color:#000;
    font-weight: 600;
}
.category-wrapper .category-inner{
    border-radius: 20%;
}
.hero-bg-image img{
  
}
.hero_home .swiper-wrapper{
     /* max-height: 450px !important;  */
}
.hero-slide-content-2{
    /* background-color:  #4c90904d !important; */
    padding: 10px 10px;
    
}
.hero-slide-content.hero-slide-content-2{
    /* top:50%; */
    margin-top:50%;
    border-radius: 10px;
    min-height: 70vh;
    border-radius: 10px 20px 30px 40px/30px;
}
.hero-slide-content.hero-slide-content-2 .btn{
   
}

.banner-3 img{height:327px; }

.border-box{
    /* box-shadow: 2px 5px 13px 2px #6c757d; */
    /* box-shadow: 0px 0px 0px 1px #6c757d5e;
    border-radius: 20px; */
    padding: 20px;
    /* margin: 5px; */
     /* margin-top: 5px;  */
    /* margin-bottom: 5px; */
    /* background: #FFFFFF;  */
}
.border-box-round{
    text-align: center;
    border-radius: 50%;
    background: #fff;
    margin: auto;
    background-color: #e8e8e8;
    box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);
    /* padding: 40px 30px; */
    /* width: auto; */
    transition: 0.3s;
}
.border-box-noshadow{
    text-align: center;
    border-radius: 10px;
    background: #fff;
    /* box-shadow: 0px 2px 15px rgb(0 0 0 / 10%); */
    /* padding: 40px 30px; */
    /* width: auto; */
    transition: 0.3s;
}
.border-box-3{
    text-align: center;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);
    /* padding: 40px 30px; */
    /* width: auto; */
    transition: 0.3s;
}
.border-box-2{
    text-align: center;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);
    /* padding: 40px 30px; */
    /* width: auto; */
    transition: 0.3s;
}
.border-box-1{
    text-align: center;
    border-radius: 10px;
    background: #fff;
    /* box-shadow: 0px 2px 15px rgb(0 0 0 / 10%); */
    /* padding: 40px 30px; */
    /* width: auto; */
    transition: 0.3s;
}


.bg-themecolor{
  background-color: #0c6942;
  color:#fff;
}
.bg-themecolor a h4{
    color:#fff !important;
}

.title-black{
    color:#000;
}
.heading .title{
    color: #00aaef;
    
}
.heading .page__title-bar{
    display: inline-block;
}
a {
    color:#271304;

}
.btn-primary {
    color: #fff;
    background-color: #2747a3 !important;
    border-color: #fff !important;
    border: 0;
    transition: all .3s ease 0s;
    border-radius: 5px;

}
.btn-secondary{
    background-color:#00aaef ;
    border-radius:12px;
    width: 100%;
}
.btn{
    width: 190px !important;
    height: 40px;
    line-height: 40px;
    margin-bottom: 5px;
}
.btn-2{
    width: 150;
    padding: 10px 20px;
    font-size:15px;
    margin-right: 20px;

}
.btn-2:hover{
    background-color:#00aaef ;
}

.btn-colored{
    background-color: #00aaef;
    border: 1px solid #fff;
    border-radius: 5px;
    color: white;
 }
 

.btn-transparent{
   background-color: transparent;
   border: 1px solid #fff;
   border-radius: 5px;
   color: white;
}

.btn-check:focus+.btn-primary, .btn-primary:focus{
    color: #00aaef;
}

h2{
    color: #03adeb !important;
}
.title.h3{
    color: #03adeb !important;
    font-size: 1.3rem !important;
}
.h3 h3{
    font-size: calc(1rem + .6vw) !important;
}
.submit{
    /* background-color:#4c9090 !important; */
}

.heading>.title:after{
    position: absolute;
    height: 2px;
    text-decoration: line-through;
    background-color: coral;
     /* background-image: url(../assets/images/border-bottom.svg); */
    /* width: 100px !important; */
    /* width: calc(10%); */
    /* content: "";
    background: no-repeat;
   
    
    height: 2px !important;
    background-color: coral;
    position:absolute;
   
    margin: auto;
    display: block;
    text-align: left;
    float:left; */
     /* bottom: -10px; */
}
.pd_lr-10px{
padding-left: 10px;
 padding-right: 10px;
}
.slider-nav-style-1 .swiper-buttons .swiper-button-next, .slider-nav-style-1 .swiper-buttons .swiper-button-prev{
background-color: #0e8bce;

}
.pt-50px{
padding-top: 40px;
}
.pb-50px{
    padding-bottom: 40px;

}
.product:hover .thumb .image img {
    transform: scale(1.1);
}
.product .thumb .image img{
    /* height: 100%; */
    width: auto;
    max-width: 100%;
    border-radius: 10px;
}
.thumb img{
    max-width: 100%;
}
.small_container{
    max-width: 80%; margin: auto;
}
/* why us */
.flex-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
}
.mbdhimg{
    top:20px;
   width: 100%;
   height: 20%;
   text-align: center;
}
#headimg span{
    padding-left: 5px;
}
@media only screen and (max-width: 600px){
    #headimg{
        width: 90%;
        /* margin-top:80px; */
        /* margin-left: 30%; */
        margin:auto;
        background-color:#276130;
        color: white;
        font-size: 25px;
        font-family:'Poppins',sans-serif ;   
        align-self: center; 
    }
    .why-us .small_container .img-fluid{
        max-height: 43px !important;
        margin-top: 10px;
    }
    .why-us .small_container .bottom_title{
        font-size: 10px;
    }
}
@media only screen and (min-width: 600px){
    #headimg{
        width: 50%;
        /* margin-top:80px; */
        /* margin-left: 30%; */
        margin:auto;
        background-color:#276130;
        color: white;
        font-size: 25px;
        font-family:'Poppins',sans-serif ;   
        align-self: center; 
    }
    .why-us .small_container .img-fluid{
        max-height: 83px !important;
    }
    
    #why-us .btn-green{
        background-color:#276130 !important;
    }
    #txtdata{
        margin-top: 20px;
        margin-left: 20%;
        color:#276130;
        font-size: 24px;
        font-family:"Georgia", "Courier New", monospace;
        width: 60%;
    }
    .imagediv{
       
          width:100%;
        height: 30%;
        margin:0;
        padding-left:30px;       
        padding-right: 30px; 
    }
    #imageid{
        align-self:flex-start;   
        padding-left:10px;
        padding-right: 10px;
        margin: 0; 
         width: max-content;
    }
    #imageid img{
        max-height: 83px;
        width: auto;
    }
    #btmtxt{
        font-size: 15px;
        color:#081a0a;  
        overflow: wrap; 
        font-family: 'Georgia',sans-serif;
        padding-left: 5px;
        padding-right: 5px;
        }
    .btndesign{
        margin-top:50px;
        background-color:#276130;
        color: white;
        font-size: 30px;
        font-weight: 600;
        padding: 0 40px 0 40px;
        font-family:'Georgia', sans-serif;
    }
    #headimg span{
        font-family: 'Georgia', sans-serif;font-size: 30px;font-weight: 600;
    }
    .custome-responsive{
        height: auto !important;
    }
}

.product .thumb .add-to-cart{
    background-color: #4c9090;
    color:#FFFFFF;
}
.footer-area .footer-top{
    padding-bottom: 0px;
}


body{
    margin: 0;
    font-family: "Rubik",sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6B6A75;
    background-color: #fff;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
    /* font-family: "Georgia",sans-serif !important; */
    /* font-family: 'STIX Two Text', serif; */
    /* font-family: "Verdana",sans-serif !important; */
    /* font-weight: 500 !important;  */
}

.mb-10px{
    margin-bottom: 10px;
}

.mb-5px{
    margin-bottom: 5px;
}

.pb-20px{
    padding-bottom: 20px;
}
.pt-20px{
    padding-top: 20px;
}

.pb-70px{
    padding-bottom: 70px;
}

.title{
   font-weight: 700 !important;
   text-transform: capitalize !important;
}
.header-top-massege p{
    font-family: "Georgia",sans-serif !important;
}
.header-top-set-lan-curr {
    font-family: "Georgia",sans-serif !important;
}
.copy-text{
    font-family: "Georgia",sans-serif !important;
}
.category-wrapper .category-inner .category-single-item .title{
    font-family: "Georgia",sans-serif !important;
    font-weight: 500 !important;
}
.footer-herading{
    text-transform: capitalize !important;
    /* text-transform:uppercase !important; */
    font-size: 18px !important;
}
.footer-area .footer-top .single-wedge .footer-links .li{
    margin-bottom: 1px !important;
}
.contact-form-style a{
    color: #14955f !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
input[type=checkbox]{
    height: 15px;
    width: min-content;
    margin-right: 5px;
}
.linkclass{
    color: #174da8 !important;
    font-weight: 700;

}
.footer-herading a{
    color:#fff !important;
}
.blog-post-content-inner p {
    font-size: 19px !important;
}
.blog-post-content-inner h6 {
    font-size: 22px !important;
}
.blog-post-content-inner h5 {
    font-size: 26px !important;
}
.blog-post-content-inner h4 {
    font-size: 30px !important;
}
.blog-post-content-inner h3 {
    font-size: 34px !important;
}
.blog-post-content-inner h2 {
    font-size: 38px !important;
}
.blog-post-content-inner h1 {
    font-size: 42px !important;
}
.product_breadcrumb h2{
    color:#fff !important;
}
.product_linkssh a{
    color:#fff !important;
}
.product_linkssh .breadcrumb-item{
    color :#fff !important;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px){
    .main-menu ul li+li {
        margin-left: 25px;
    }
}

  
.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  #scrollUp{
    background-color: #444954;
  }
  .txt-white{
    color:#fff !important;
  }
  .bg_job1{
    background-image: url(../assets/images/bg-images/job1.png);
    background-size: cover;
    background-repeat:no-repeat;
  }
  .bg_job2{
    background-image: url(../assets/images/bg-images/job2.png);
    background-size: cover;
    background-repeat:no-repeat;
  }
  .bg_job3{
    background-image: url(../assets/images/bg-images/job3.png);
    background-size: cover;
    background-repeat:no-repeat;
  }
  .bg_job4{
    background-image: url(../assets/images/bg-images/job4.png);
    background-size: cover;
    background-repeat:no-repeat;
  }
  .bg_job5{
    background-image: url(../assets/images/bg-images/job5.png);
    background-size: contain;
    background-repeat:no-repeat;
  }
  .bg_job6{
    background-image: url(../assets/images/bg-images/job6.png);
    background-size: cover;
    background-repeat:no-repeat;
  }
  .bg_job7{
    background-image: url(../assets/images/bg-images/job7.png);
    background-size: cover;
    background-repeat:no-repeat;
  }
  .bg_job8{
    background-image: url(../assets/images/bg-images/job8.png);
    background-size: cover;
    background-repeat:no-repeat;
  }
  .bg_job9{
    background-image: url(../assets/images/bg-images/job9.png);
    background-size: cover;
    background-repeat:no-repeat;
  }
  .bg_job10{
    background-image: url(../assets/images/bg-images/job10.png);
    background-size: cover;
    background-repeat:no-repeat;
  }

  .bg-strip1{
    background-image: url(../assets/images/bg-images/strip_1.jpg);
    background-size: contain;
    
  }
  .bg-strip2{
    background-color:#02aaed !important; ;
background-size: contain;
  }
  .main-menu ul li a:hover{
    color:#1ec6ff !important;
  }
  .link-follow a:hover{
    background-color:#1ca8d8 ;
  }
  .offcanvas.offcanvas-mobile-menu .offcanvas-close{
    background-color:#1ca8d8 ;
  }
  .offcanvas .inner .offcanvas-menu ul li:hover>a{
    color:#1ca8d8;
  }
 .border-top{
    border-top: 1px solid ;
 }
 .single_img{
    max-width: 80%;

 }
 .f_color_1{
    color:#1ca8d8 !important;
 }
 .bg-gray{
    
        background-color: #eeeeee !important;
    
 }
 .bg-blue{
    background-color:#1ca8d8 ;
 }
 .bg-gray-dark{
    background-color:  #6c757d
 }
 .round10{
    border-radius: 10px;
 }
 
 @media only screen and (max-width: 600px){
    .vcenter{
        /* margin:auto !important;
        vertical-align: baseline !important; */
        top:20%;
        position: relative;
        overflow: hidden;
     }
 }
 @media only screen and (min-width: 700px){
    .vcenter{
        /* margin:auto !important;
        vertical-align: baseline !important; */
        top:40%;
        position: relative;
        overflow: hidden;
     }
     .txt-forimg{
        width: 100%;
        height: auto;
     }
 }
 .btn_sim{
    min-width: 100%;
    line-height: inherit;
    padding: 1px 6px;
 }
 label{
    /* margin-bottom: 2px; */
    margin-bottom: 0px;
    font-weight: bold;
    font-size: 14px;
 }
 .form-group{
    margin-bottom: 25px;
 }

 audio::-webkit-media-controls-timeline,
video::-webkit-media-controls-timeline {
    display: none;
}

@media only screen and (min-width: 700px){
    #vid_home{
        width:80%;
    }
}
@media only screen and (max-width: 600px){
    #vid_home{
        width:100%;
    }
}

.vertical-center-noabs{
    margin: 0;
    position: relative;
    top: 30%; 
    vertical-align: middle;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
@media only screen and (max-width: 600px){
    .adjust-layouts-img{
        /* min-height: 200px !important; */
    }
    .vertical-center-noabs_absolute{
        /*
        margin: 0;
        position: absolute;
        bottom: 30%; 
        vertical-align: middle;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        --bs-gutter-x: 30px;
        margin-top: -13%;
        */
        margin: 0;
        position: absolute;
        top: 25%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}
@media only screen and (min-width: 650px){
    .vertical-center-noabs_absolute{
        /*
        margin: 0;
        position: absolute;
        bottom: 30%; 
        vertical-align: middle;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        --bs-gutter-x: 30px;
        margin-top: -13%;
        */
        margin: 0;
        position: absolute;
        top: 30%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        /* width:100% */
    }
}


.h1, h1 {
     /* font-size: 4vw !important; */

}
p{
    /* font-size: 2vw !important; */
}
body{
    /* font-size: 1.25vw !important; */
}
@media only screen and (min-width: 700px){
.contain-gb{
    background-repeat:no-repeat;background-size:100% auto;background-position:center;
}
}

.bg-white{
    background-color: #fff o !important;
}

.form-control{

    padding:6px 12px 6px 12px;
    /* background-color:#e8e8e8; */
    min-height: 40px;
    border-radius:4px;
    }
    
    input{
    height:40px;
    }
 .dia_cart{
    padding: 20px 20px;
 }
 @media only screen and (min-width: 600px){
 .set-screen{
    width:auto;
    height: 100vh;
       /* max-height: 550px; */
 }
}
@media only screen and (max-width: 650px){
    .set-screen{
        height: auto;
        width:100%;
    }
   }
 .Bg-Img-Left{
    left: 40%;/* for img left text shoudl right*/
 }
 .Bg-Img-Right{
    /* right:40%; */
    right: 30%;
 }
 .bg_transparent{
    background-color: transparent !important;
 }
 @media only screen and (max-width: 675px){
.col-xs-6 {
    width: 50% !important;
}
}
.center-middle{
    margin: auto;
   
}
.center-middle-white{
    margin: auto;
    color:#fff;
    z-index: 22;
}
.case-divs{
    position: relative;
}
.bg-case-strip{
    /* position: absolute;
    background-color: #02aaed;
    height: 30vh;
    margin-top: 20px;
    max-width: 90%;
    opacity: 1; */

    position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  max-width: 100%;
  height: 30vh;
  /* background-color: #02aaed; */
  background-color:rgba(2, 170, 237, 1);
  z-index: 1;
  /* margin-left:-100%;
  margin-right:-100%; */
    /* margin-top: 50%; */
    right:80%;
}
@media only screen and (min-width: 600px){
.bg-case-strip-left{
    /* position: absolute;
    background-color: #02aaed;
    height: 30vh;
    margin-top: 20px;
    max-width: 90%;
    opacity: 1; */

    position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  max-width: 100%;
  height: 30vh;
  /* background-color: #02aaed; */
  background-color:rgba(2, 170, 237, 1);
  z-index: 1;
  /* margin-left:-100%;
  margin-right:-100%; */
    /* margin-top: 50%; */
    left:12%;
    overflow: hidden;
}
}

.img-div{
    z-index: 22 !important;
}

 .center-middle{
    z-index: 22 !important;
}
.mob_lenght h1:first-of-type{
    /* float: left; */
}
.font-sm{
    font-size: 16px !important;
    line-height: 10px !important;
}

 /* .ml-2{
    margin-right: 2px;
 }    */

 



    



