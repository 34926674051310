.card-upd{
    box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2);
    margin-bottom: 1rem;
}
.upd-icon{
    max-width: 10%;
}
.widget-user-2 .widget-user-header {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    padding: 1rem;
}
.widget-user-image {
    float: left;
    height: auto;
}
.widget-user-2 .widget-user-desc, .widget-user-2 .widget-user-username {
    margin-left: 75px;
}
.card {
    box-shadow: 0 6px 12px 0 rgba(0,0,0,.2);
}
.gion .fa {
    width: 20px;
    color: #055094;
}

.bg-danger, .bg-danger>a,.bg-danger>.widget-user-desc {
    color: #fff!important;
}
.bg-danger {
    background-color: #dc3545!important;
}
.bg-info, .bg-info>a,.bg-info>.widget-user-desc {
    color: #fff!important;
}
.bg-info {
    background-color: #17a2b8!important;
}

